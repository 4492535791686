<template>
  <div class="container">
    <a-row>
      <a-col :span="6">
        <div >Select Map</div>
        <a-select placeholder="Select map" v-model="currentMap" style="width: 80%" @change="changeMap">
          <a-select-option value="1">
            Map 1
          </a-select-option>
          <a-select-option value="2">
            Map 2
          </a-select-option>
          <a-select-option value="3">
            Map 3
          </a-select-option>
          <a-select-option value="4">
            Map 4
          </a-select-option>
          <a-select-option value="5">
            Map 5
          </a-select-option>
          <a-select-option value="6">
            Map 6
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="6">
        <div >Select Rare</div>
        <a-select placeholder="Select rare" v-model="currentRare" style="width: 80%"  @change="changeRank">
          <a-select-option value="1">
            Rare 1
          </a-select-option>
          <a-select-option value="2">
            Rare 2
          </a-select-option>
          <a-select-option value="3">
            Rare 3
          </a-select-option>
          <a-select-option value="4">
            Rare 4
          </a-select-option>
          <a-select-option value="5">
            Rare 5
          </a-select-option>
          <a-select-option value="6">
            Rare 6
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="6">
        <div>Current rw</div>
        <a-input placeholder="Current Price" v-model="currentPriceReward" disabled style="width: 80%"/>
      </a-col>
      <a-col :span="6">
        <div>New rw</div>
        <a-input v-model="newRw" placeholder="New Price" style="width: 80%"/>
        <a-button class="btn" type="primary" @click="submit">
          Submit
        </a-button>
      </a-col>
    </a-row>

  </div>
</template>
<script>
export default {
  name: 'UpdatePriceNFT',
  data () {
    return {
      currentMap: null,
      currentRare: null,
      newRw: ''
    }
  },
  computed: {
    currentPriceReward () {
      return this.$store.getters.currentPriceReward
    }
  },
  created () {
    this.$store.commit('setCurrentPriceReward', '')
  },
  methods: {
    changeRank () {
      this.getCurrentPrice()
    },
    changeMap () {
      this.getCurrentPrice()
    },
    getCurrentPrice () {
      this.newRw = ''
      this.$store.commit('setCurrentPriceReward', '')
      if (this.currentMap && this.currentRare) {
        this.$store.dispatch('getReward', {
          map: this.currentMap, rank :this.currentRare})
      }
    },
    async submit () {
      if (this.newRw && this.newRw.trim('') !== '') {
      const _rs = await this.$store.dispatch('setRw', {
          map: this.currentMap,
          rank: this.currentRare,
          maxReward: this.newRw
        })
      if (_rs.code === 1) {
        this.$message.info('Update Success');
      }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .container {
    width: 80%;
    margin: 20% auto;
  }
  .btn {
    margin: 20px auto;
  }
</style>